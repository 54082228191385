const themes = {
  transparent: { bg: "rgba(0,0,0,0)", fg: "black" },
  // Base colors
  default: { bg: "black", fg: "white" },
  white: { bg: "white", fg: "black" },
  black: { bg: "black", fg: "white" },
  dark: { bg: "black", fg: "white" },
  slate: { bg: "#455a64", fg: "white" },
  // Generic colors
  bodyGrey: { bg: "rgb(242, 246, 249", fg: "black" },
  lightBlueGrey: { bg: "#a8c6df", fg: "black" },
  lightBlueGrey20: { bg: "rgba(168, 198, 223, 0.2)", fg: "black" },
  lightBlueGrey80: { bg: "rgba(168, 198, 223, 0.8)", fg: "black" },
  lightBlueGrey90: { bg: "rgba(168, 198, 223, 0.9)", fg: "black" },
  bluishGrey: { bg: "#78909c", fg: "white" },
  lightGrey: { bg: "#f8f9f9", fg: "black" },
  darkSkyBlue10: { bg: "rgba(30, 136, 229, .1)", fg: "#455a64" },
  lightBlueGreyTwo: { bg: "#d8e5ef", fg: "black" },
  darkGreyBlue: { bg: "#2c4059", fg: "white" },
  darkGreyBlue16: { bg: "rgba(44, 64, 90, .16)", fg: "black" },
  dark2: { bg: "rgba(0,0,0, .02)", fg: "black" },
  // dark2-no-alpha:          {bg: '#90a4ae',                     fg: 'black'},
  dark3: { bg: "rgba(0,0,0, .03)", fg: "black" },
  charcoalGrey: { bg: "#37474f", fg: "white" },
  paleGrey: { bg: "#fafbfc", fg: "black" },
  greyBlue: { bg: "#8dabc4", fg: "white" },
  blueyGrey: { bg: "#90a4ae", fg: "white" },
  paleGreyTwo: { bg: "f2f6f9", fg: "black" },
  paleGreyThree: { bg: "#e5eef5", fg: "black" },
  lightBlueGreyThree: { bg: "#c5d9e8", fg: "black" },
  charcoalGrey60: { bg: "rgba(55, 71, 79, 0.6)", fg: "white" },
  charcoalGrey80: { bg: "rgba(55, 71, 79, 0.8)", fg: "white" },
  // Blue
  azure: { bg: "#0093ee", fg: "white" },
  darkSkyBlue: { bg: "#1e88e5", fg: "white" },
  // Green
  appleGreen: { bg: "#81c926", fg: "white" },
  // Application
  errorRed: { bg: "#ff1744", fg: "white", light: "#ff96ab" },
  productionBlue: { bg: "#2979ff", fg: "white", light: "#c2d9ff" },
  pauseAzure: { bg: "#03a9f4", fg: "white", light: "#92dcfe" },
  materialViolet: { bg: "#aa00ff", fg: "white", light: "#d9f" },
  setupYellow: { bg: "#ffab00", fg: "white", light: "#ffcd66" },
  checkGreen: { bg: "#00c853", fg: "white", light: "#00f666" },
  neutralGrey: { bg: "#b0bec5", fg: "white", light: "#c2cdd2" },
  doneGrey: { bg: "#ebeef0", fg: "#a8c6df", light: "#ebeef0" },
};

export const statuses = {
  MATERIAL: themes.materialViolet,
  SETUP: themes.setupYellow,
  EXTERNAL: themes.productionBlue,
  PRODUCTION: themes.productionBlue,
  APPROVAL: themes.checkGreen,
  CHECK: themes.checkGreen,
  NEW: themes.neutralGrey,
  ERROR: themes.errorRed,
  INTERRUPT: themes.neutralGrey,
  DONE: themes.doneGrey,
  PAUSE: themes.pauseAzure,
  PROBLEM: themes.errorRed,

  DRAFT: themes.neutralGrey,
  QUEUE: themes.neutralGrey,
  TODO: themes.neutralGrey,
  WIP: themes.productionBlue,
  // ARCHIVED: themes.doneGrey,
  // CANCELLED: themes.doneGrey,
};

export default themes;
