import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../actions";
import confirmAction from "../../libs/confirm-action";

function Error({ error }) {
  const history = useHistory();
  const dispatch = useDispatch();

  function confirm() {
    dispatch(setUser());
    history.push("/");
  }
  return (
    <section className="section is-small">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-6 is-offset-3 has-text-centered">
            <p className="title is-2 is-danger">
              <FormattedMessage
                id="app.shared.error"
                defaultMessage="Sorry, error"
              />
            </p>
            {error && error.message && <p>{error.message}</p>}
            <button
              type="button"
              className="button is-large is-azure is-light"
              onClick={() => confirmAction({ confirm })}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

Error.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};
export default Error;
