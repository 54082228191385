import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form } from "react-final-form";

import Field from "../../../../components/elements/Field";
import { setUser } from "../../../../actions";
import toast from "../../../../libs/toast";
import onError from "../../../../libs/error-logger";
import config from "../../../../config";

const API = config.api;

/**
 * @name LoginForm
 *
 * @returns {*}
 * @constructor
 */
function LoginForm() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  async function onSubmit({ email, password }) {
    console.log("Submit login!", { email, password });
    try {
      const response = await fetch(`${API}/api/v1/authentication/sign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      console.log("Response received", response);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const data = await response.json();
      dispatch(setUser(data));
      toast({ title: intl.formatMessage({ id: "app.pages.login.toast" }) });
      history.push("/scheduling/jobs");
    } catch (error) {
      console.error("Error received", JSON.stringify(error));
      dispatch(setUser());
      onError(error, intl.formatMessage({ id: "app.shared.error.login" }));
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, submitting, invalid }) => (
        <form
          onSubmit={handleSubmit}
          data-cy="authentication-form"
          onKeyDown={(e) => {
            if (e.ctrlKey && e.code === "KeyS") {
              e.preventDefault();
              !invalid && handleSubmit(e);
            }
          }}
        >
          <div className="field">
            <div className="control">
              <Field
                name="email"
                className="input is-large"
                data-cy="authentication-form-field-email"
                required
                component="input"
                type="email"
                disabled={submitting}
                placeholder={intl.formatMessage({
                  id: "app.pages.login.email.placeholder",
                })}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <Field
                name="password"
                data-cy="authentication-form-field-password"
                className="input is-large"
                required
                component="input"
                type="password"
                disabled={submitting}
                placeholder={intl.formatMessage({
                  id: "app.pages.login.password.placeholder",
                })}
              />
            </div>
          </div>
          <button
            type="submit"
            className="button is-body-grey is-large is-fullwidth"
            disabled={submitting || pristine}
            title={intl.formatMessage({
              id: "app.shared.save.tooltip",
              defaultMessage: "Save changes (CTRL+S)",
            })}
          >
            <FormattedMessage
              id="app.pages.login.signin.button"
              defaultMessage="Sign in"
            />
          </button>
        </form>
      )}
    />
  );
}

LoginForm.propTypes = {};

export default LoginForm;
