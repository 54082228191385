import gantt from "@agile-factory/external-gantt";

export default function load(language) {
  switch (language) {
    case "ru":
      gantt.i18n.setLocale("ru");
      break;
    case "it":
      gantt.i18n.setLocale("it");
      break;
    case "en":
    default:
      break;
  }
}
