import React from "react";
import { FormattedMessage } from "react-intl";

import NavBar from "../../components/elements/NavBar";
import NavBarItem from "../../components/elements/NavBarItem";
import HasFeature from "../../components/helpers/HasFeature";

function AnalyticsNavBar() {
  return (
    <NavBar>
      <div className="navbar-start">
        <HasFeature feature={"ANALYTICS:JOBS"}>
          <NavBarItem to="/analytics/jobs" data-cy="navbar-analytics-jobs">
            <span className="icon is-small">
              <i className="icon-fv-jobs-icn" />
            </span>
            <FormattedMessage
              id="app.pages.analytics.navbar.jobs"
              defaultMessage="Production orders"
            />
          </NavBarItem>
        </HasFeature>
        <HasFeature feature={"ANALYTICS:WORKERS"}>
          <NavBarItem
            to="/analytics/workers"
            data-cy="navbar-analytics-workers"
          >
            <span className="icon is-small">
              <i className="icon-fv-operators-icn" />
            </span>
            <FormattedMessage
              id="app.pages.analytics.navbar.workers"
              defaultMessage="Staff"
            />
          </NavBarItem>
        </HasFeature>
      </div>
    </NavBar>
  );
}

export default AnalyticsNavBar;
