/**
 * Created by andrea on 19/06/2017.
 */
import { SET_MACHINES } from "../actions";

const initialState = {
  machines: [],
};
/**
 * Machines reducer
 * @param state
 * @param action
 * @returns {*}
 */
function machinesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MACHINES:
      return { ...state, machines: action.machines };
    default:
      return state;
  }
}

export default machinesReducer;
