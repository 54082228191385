import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

import config from "../config";
import store from "../store";
import introspectionQueryResultData from "./fragmentTypes.json";

console.log(
  "Starting graphql on",
  config.graphql.api,
  "with websockets on",
  config.graphql.ws
);
const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.graphql.ws,
  })
);
const httpLink = new HttpLink({ uri: config.graphql.api });
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const middlewareLink = setContext(() => {
  const state = store.getState();
  const token = state?.user?.user?.token;
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return {
    headers: {},
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    // errorPolicy: 'all',
  },
};
const client = new ApolloClient({
  link: middlewareLink.concat(splitLink),
  cache,
  defaultOptions,
});

export { cache };

export default client;
