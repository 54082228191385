import React from "react";
// import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";

import Loader from "../components/helpers/Loader";

function FullpageLoader() {
  return (
    <section className="hero is-fullheight is-light is-bold is-large">
      <div className="hero-body">
        <div className="container has-text-centered">
          <Loader message={null} isLarge />
          <h1 className="title is-1">
            <FormattedMessage
              id="app.shared.brand"
              defaultMessage="Agile Factory"
            />
          </h1>
        </div>
      </div>
    </section>
  );
}

FullpageLoader.propTypes = {};
export default FullpageLoader;
