/**
 * Created by andrea on 19/06/2017.
 */
import { SET_USER } from "../actions";

const initialState = {
  user: null,
};
/**
 * User reducer
 * @param state
 * @param action
 * @returns {*}
 */
function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.user };
    default:
      return state;
  }
}

export default userReducer;
