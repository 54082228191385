import React from "react";
import { capture } from "@agile-factory/module-error-tracking-browser";
import Unknown from "./Unknown";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    capture(error);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Unknown>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && String(this.state.error)}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </Unknown>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
