import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/it";

export default function load(language) {
  switch (language) {
    case "ru":
    case "it":
      window.__localeId__ = language;
      moment.locale(language);
      break;
    default:
      window.__localeId__ = "en";
      break;
  }
}
