import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import Helmet from "react-helmet";
import { persistStore } from "redux-persist";
import ErrorTracking from "@agile-factory/module-error-tracking-browser";

import * as localization from "../config/localization";
import store from "../store";
import graphql from "../libs/graphql";
import * as Loaders from "../loaders";
import Routes from "./Routes";
import config from "../config";

const persistor = persistStore(store, null, () => {
  console.log("Store rehydrated");
});

/**
 * React Application
 *
 * @returns {*}
 * @constructor
 */
function Application() {
  const lang = localization.language.substring(0, 2);

  useEffect(() => {
    ErrorTracking.initialize({
      customer: config.customer,
      dsn: config.sentry,
      release: config.build,
      environment: config.env,
    });
  });
  return (
    <IntlProvider
      locale={localization.language}
      messages={localization.messages}
      timeZone={localization.timeZone}
    >
      <ApolloProvider client={graphql}>
        <PersistGate
          persistor={persistor}
          loading={
            <div>
              <Helmet
                htmlAttributes={{ lang, class: "home-layout" }}
                titleAttributes={{ lang }}
              />
              <Loaders.FullPage className="is-fullheight" />
            </div>
          }
        >
          <Provider store={store}>
            <Routes language={localization.language} />
          </Provider>
        </PersistGate>
      </ApolloProvider>
    </IntlProvider>
  );
}

export default Application;
