import userReducer from "./user.reducer";
import machinesReducer from "./machines.reducer";
import sysConfigReducer from "./sys-config.reducer";

/**
 * Combine reducers (application reducers and apollo reducer)
 * @type {Reducer<S>}
 */
const reducers = {
  user: userReducer,
  machines: machinesReducer,
  sysConfig: sysConfigReducer,
};
export default reducers;
