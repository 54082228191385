import React from "react";
import { useTitle } from "hoofd";
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

/**
 * @description Show a 401 page
 * @constructor
 */
function NotAuthorized() {
  useTitle("401");
  const history = useHistory();
  return (
    <section className="hero is-large is-light is-fullheight is-bold">
      <Helmet htmlAttributes={{ class: "home-layout" }} />
      <div className="hero-body">
        <div className="container">
          <h1 className="title">
            <FormattedMessage
              id="app.pages.unauthorized"
              defaultMessage="Sorry, you are not authorized to view this page"
            />
          </h1>
          <Link to="/" className="button is-text">
            <FormattedMessage
              id="app.pages.unauthorized.button.home"
              defaultMessage="Go to home"
            />
          </Link>
          <button
            type="button"
            className="button is-text"
            onClick={() => history.goBack()}
          >
            <FormattedMessage
              id="app.pages.unauthorized.button.back"
              defaultMessage="Go back"
            />
          </button>
        </div>
      </div>
    </section>
  );
}

export default NotAuthorized;
