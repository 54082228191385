import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useTitle } from "hoofd";

/**
 * @description Show a 404 page
 * @constructor
 */
function NotFound() {
  useTitle("404");
  return (
    <section className="hero is-large is-light is-fullheight is-bold">
      <Helmet htmlAttributes={{ class: "home-layout" }} />
      <div className="hero-body">
        <div className="container">
          <h1 className="title">
            <FormattedMessage
              id="app.pages.not_found"
              defaultMessage="Sorry, page not found"
            />
          </h1>
          <h3 className="subtitle">
            <ul>
              <li>
                <Link to="/" className="fg-slate">
                  <FormattedMessage
                    id="app.pages.not_found.go_to_home"
                    defaultMessage="Go to home page"
                  />
                </Link>
              </li>
            </ul>
          </h3>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
