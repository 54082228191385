import { capture } from "@agile-factory/module-error-tracking-browser";
import intl from "./format-message";
import toast from "./toast";

const DEFAULT_MESSAGE = intl.formatMessage({ id: "app.shared.error.generic" });

/**
 * Shows an error message
 * @param err
 * @param text
 */
// TODO: log somewhere outside...
function onError(err, text = DEFAULT_MESSAGE) {
  capture(err);
  // const isProduction = process.env.NODE_ENV === 'production';

  const message = String(err) || "";
  toast({
    icon: "error",
    title: `Generic error\n${message.replace("ApolloError: Error:", "")}`,
  });
}

export default onError;
