import React from "react";
import ReactDOM from "react-dom/client";

import { unregister } from "./registerServiceWorker";

// Styles
import "@agile-factory/styles-icons";
import "bulma/css/bulma.css";
import "sweetalert2/dist/sweetalert2.css";
import "./css/style.css";
import "react-datepicker/dist/react-datepicker.css";

// Routing
import Routes from "./routes";
// Polyfills
import "./libs/polyfills";

import { withClearCache } from "./hocs";

const App = withClearCache(Routes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
unregister();
