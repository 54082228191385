import { shouldPolyfill } from "@formatjs/intl-relativetimeformat/should-polyfill";
import getMessages from "../locales";
import time from "./libs/time";
import gantt from "./libs/gantt";
import config from ".";

const localeData = getMessages();

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const language =
  config.forceLang ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language;

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

async function polyfill(locale) {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import("@formatjs/intl-relativetimeformat/polyfill");
  }

  if (Intl.RelativeTimeFormat.polyfilled) {
    switch (locale) {
      case "it":
        await import("@formatjs/intl-relativetimeformat/locale-data/it");
        break;
      case "ru":
        await import("@formatjs/intl-relativetimeformat/locale-data/ru");
        break;
      case "en":
        await import("@formatjs/intl-relativetimeformat/locale-data/en");
        break;
      default:
        throw new Error("Locale not configure, see localization.js");
    }
  }
}

(async function () {
  await polyfill(languageWithoutRegionCode);
})();

console.log("Current language:", languageWithoutRegionCode);
time(languageWithoutRegionCode);

// Try full locale, try locale without region code, fallback to 'en'
const messages =
  localeData[languageWithoutRegionCode] ||
  localeData[language] ||
  localeData.en;

gantt(languageWithoutRegionCode);

export { messages, language, languageWithoutRegionCode, timeZone };
