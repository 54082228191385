import swal from "sweetalert2";
import intl from "./format-message";

/**
 * Toast message
 * @param options
 */
function toast(options = {}) {
  console.log(">> toast");
  swal.fire({
    icon: "success",
    title: "Message is missing",
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    ...options,
  });
}

export function deleted() {
  toast({
    icon: "success",
    title: intl.formatMessage({
      id: "app.shared.delete_success",
      defaultMessage: "Removed successfully",
    }),
  });
}

export function added() {
  toast({
    icon: "success",
    title: intl.formatMessage({
      id: "app.shared.added_success",
      defaultMessage: "Added successfully",
    }),
  });
}

export function printed() {
  toast({
    icon: "success",
    title: intl.formatMessage({
      id: "app.shared.print_success",
      defaultMessage: "Printed successfully",
    }),
  });
}

export function saved() {
  toast({
    icon: "success",
    title: intl.formatMessage({
      id: "app.shared.save_success",
      defaultMessage: "Saved successfully",
    }),
  });
}

export function sent() {
  toast({
    icon: "success",
    title: intl.formatMessage({
      id: "app.shared.sent_success",
      defaultMessage: "Sent successfully",
    }),
  });
}

export default toast;
