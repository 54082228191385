import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function hasFeature(feature, enabledFeatures) {
  // Split by comma, space or both to test OR
  const features = feature
    .split(/,| /)
    .filter((str) => str !== null && str !== "");
  let enabled = false;
  features.some((featureName) => {
    const regex = new RegExp(featureName);
    if (enabledFeatures instanceof Array) {
      enabled = enabledFeatures.some((feature) => regex.test(feature));
    } else if (enabledFeatures instanceof Object) {
      if (Object.keys(enabledFeatures).some((feature) => regex.test(feature))) {
        const foundedFeatures = Object.keys(enabledFeatures).filter((key) =>
          regex.test(key)
        );
        enabled = foundedFeatures.some((key) => enabledFeatures[key]);
      }
    }
    return enabled; // This will break the loop if enabled is true
  });
  return enabled;
}

/**
 * Checks if feature is enabled
 *
 * @param feature
 * @param children
 * @return {*}
 * @constructor
 */
function HasFeature({ feature, children }) {
  //Get system configuration features
  const { sysConfig } = useSelector(({ sysConfig }) => ({
    sysConfig,
  }));
  const features = sysConfig.features;
  // Check if feature is enabled with regex
  if (hasFeature(feature, features)) {
    return <>{children}</>;
  }
  return null;
}

HasFeature.propTypes = {
  feature: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default HasFeature;

export { hasFeature };
